import axios from 'axios';

import { AuthTokenResponse, LoginResponse } from './api.types';
import { UNAUTHORIZED_STATUS_CODE } from '../../components/components.constant';
import configuration, { ENDPOINT } from '../../config';
import {
  getApiToken,
  getRefreshToken,
  redirectToErrorPage,
  removeWebToken,
  setApiToken,
  setWebToken,
} from '../auth.service';

export const instance = axios.create({
  baseURL: configuration.REACT_APP_API_URI,
  headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.request.use(
  (config) => {
    const token = getApiToken();

    if (token) {
      config.headers = config.headers ?? {};
      config.headers.Authorization = token;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  async (response) => {
    const refreshToken = getRefreshToken();

    if (refreshToken) {
      try {
        const { jwtToken } = await axios
          .get<AuthTokenResponse>(`${configuration.REACT_APP_AUTH_URL}${ENDPOINT.REFRESH_TOKEN}/${refreshToken}`, {
            withCredentials: true,
          })
          .then((loginResponse) => loginResponse.data);

        setWebToken(jwtToken);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          removeWebToken();
          redirectToErrorPage(error.response?.status);
        }
      }
    }

    return response;
  },
  async (error) => {
    const { response, config } = error;

    if (response) {
      if (response.status === UNAUTHORIZED_STATUS_CODE && !config.retry && config.url !== ENDPOINT.LOGIN) {
        config.retry = true;

        try {
          const { token } = await instance
            .get<LoginResponse>(ENDPOINT.LOGIN)
            .then((loginResponse) => loginResponse.data);

          setApiToken(token);

          return await instance(config);
        } catch (retryError) {
          if (axios.isAxiosError(retryError)) {
            const { response: retryResponse } = retryError;

            if (retryResponse && retryResponse.data) {
              return Promise.reject(retryResponse.data);
            }
          } else {
            return Promise.reject(retryError);
          }
        }
      } else {
        redirectToErrorPage(response.status);
      }
    }

    return Promise.reject(error);
  }
);
