import styles from './EmailLink.module.scss';

interface LinkPropsType {
  email: string;
}

function EmailLink({ email }: LinkPropsType): JSX.Element {
  return (
    <a href={`mailto:${email}`} className={styles.link}>
      {email}
    </a>
  );
}

export default EmailLink;
