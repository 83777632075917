import { TherapeuticType } from '../../../services/api/api.types';
import MenuGroup from '../menuGroup/MenuGroup';
import SideMenu, { SideMenuPropsType } from '../SideMenu';

interface FlatSideMenuPropsType extends Omit<SideMenuPropsType, 'children'> {
  list: TherapeuticType[];
}

function FlatSideMenu({ list, isOpen, onMenuIconClick }: FlatSideMenuPropsType): JSX.Element {
  return (
    <SideMenu isOpen={isOpen} onMenuIconClick={onMenuIconClick}>
      {list.map((item) => (
        <MenuGroup key={item.code} group={item} />
      ))}
    </SideMenu>
  );
}

export default FlatSideMenu;
