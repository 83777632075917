import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import { ProductHeader } from '../services/api/api.types';

interface ProductTableSettingsProviderPropsType {
  children?: ReactNode;
}

interface ContextType {
  settings: ProductHeader[];
  setTableSettings: (headers: ProductHeader[]) => void;
}

const TableSettingsContext = createContext({} as ContextType);

function ProductTableSettingsProvider({ children = null }: ProductTableSettingsProviderPropsType): JSX.Element {
  const [settings, setSettings] = useState<ProductHeader[]>([]);

  const setTableSettings = (headers: ProductHeader[]): void => {
    setSettings(headers);
  };

  const contextValue = useMemo(() => ({ settings, setTableSettings }), [settings]);

  return <TableSettingsContext.Provider value={contextValue}>{children}</TableSettingsContext.Provider>;
}

export const useTableSettings = (): ProductHeader[] => {
  const { settings } = useContext(TableSettingsContext);

  return settings;
};

export const useSetTableSettings = (): ((headers: ProductHeader[]) => void) => {
  const { setTableSettings } = useContext(TableSettingsContext);

  return setTableSettings;
};

export default ProductTableSettingsProvider;
