import { TabContext, TabPanel } from '@mui/lab';
import TabList from '@mui/lab/TabList';
import { Tab } from '@mui/material';

import tabStyles from './AreaTabs.module.scss';
import { useHashNavigation } from '../../../hooks/useHashNavigation';
import { OverviewSection } from '../../../services/api/api.types';
import ProductTableSettingsProvider from '../../../utils/ProductTableSettingsProvider';
import RefProvider from '../../../utils/RefProvider';
import { AreaTabNames, AreaTabValues } from '../../components.constant';
import Overview from '../../overview/Overview';
import Products from '../../products/Products';
import panelStyles from '../../tabs/Tabs.module.scss';

interface AreaTabsPropsType {
  overviewSections: OverviewSection[];
  areaName: string;
}

function AreaTabs({ overviewSections, areaName }: AreaTabsPropsType): JSX.Element {
  const [value, handleChange] = useHashNavigation(AreaTabValues.Overview);

  return (
    <TabContext value={value}>
      <TabList className={tabStyles.tabs} onChange={handleChange}>
        {Object.entries(AreaTabNames).map(([key, enumValue]) => (
          <Tab
            label={enumValue}
            value={AreaTabValues[key as keyof typeof AreaTabValues]}
            data-text={enumValue}
            key={enumValue}
          />
        ))}
      </TabList>
      <TabPanel value={AreaTabValues.Overview} className={`${panelStyles.tabPanel} ${panelStyles.area}`}>
        <RefProvider>
          <Overview sections={overviewSections} areaName={areaName} />
        </RefProvider>
      </TabPanel>
      <TabPanel value={AreaTabValues.Products} className={panelStyles.tabPanel}>
        <ProductTableSettingsProvider>
          <Products />
        </ProductTableSettingsProvider>
      </TabPanel>
    </TabContext>
  );
}

export default AreaTabs;
