import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Drawer, IconButton } from '@mui/material';
import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import styles from './SideMenu.module.scss';
import { useFooterRef } from '../../utils/FooterRefProvider';
import { getElementVisibleHeight } from '../../utils/getElementVisibleHeight';
import { useHeaderHeight } from '../../utils/OffsetProvider';

export interface SideMenuPropsType {
  isOpen: boolean;
  children?: ReactNode;
  onMenuIconClick(): void;
}

function SideMenu({ isOpen, children = null, onMenuIconClick }: SideMenuPropsType): JSX.Element {
  const iconButtonClasses = classNames(styles.iconButton, { [styles.iconButton_close]: isOpen });
  const drawerClasses = classNames(styles.drawer, { [styles.drawer_closed]: !isOpen });

  const [footerVisibleHeight, setFooterVisibleHeight] = useState(0);
  const [sideMenuHeight, setSideMenuHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const footerRef = useFooterRef();
  const headerHeight = useHeaderHeight();

  const getFooterVisibleHeight = useCallback(
    (): number => getElementVisibleHeight(footerRef?.current ?? null),
    [footerRef]
  );

  // Set Footer visible height and SideMenu height when document.body size changes
  useResizeObserver(document.body, () => {
    const { current } = containerRef;

    if (current && current.clientHeight + headerHeight !== sideMenuHeight) {
      setSideMenuHeight(current.clientHeight - headerHeight);
    }

    setFooterVisibleHeight(getFooterVisibleHeight());
  });

  // Listen the Footer visible position on scroll
  useEffect(() => {
    const debouncedScrollHandler = debounce((): void => setFooterVisibleHeight(getFooterVisibleHeight()), 100);

    window.addEventListener('scroll', debouncedScrollHandler);

    return () => {
      window.removeEventListener('scroll', debouncedScrollHandler);
    };
  }, [getFooterVisibleHeight]);

  // Set the initial Side Menu height and the initial Footer visible height
  useEffect(() => {
    setSideMenuHeight(Math.max((ref.current?.clientHeight ?? 0) - headerHeight, 0));
  }, [headerHeight]);

  // Resize SideMenu depending on the Footer visible height
  useEffect(() => {
    const { current } = ref;

    if (current && sideMenuHeight) {
      current.setAttribute('style', `height: ${sideMenuHeight - getFooterVisibleHeight()}px`);
    }
  }, [footerVisibleHeight, getFooterVisibleHeight, sideMenuHeight]);

  return (
    <div className={styles.container} ref={containerRef}>
      <IconButton className={iconButtonClasses} onClick={onMenuIconClick}>
        {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
      <Drawer
        open={isOpen}
        variant="persistent"
        anchor="left"
        className={drawerClasses}
        data-testid="drawer"
        PaperProps={{ id: 'drawerPaper', ref }}
      >
        {children}
      </Drawer>
    </div>
  );
}

export default SideMenu;
