import styles from './AreaGroup.module.scss';
import { TherapeuticType } from '../../services/api/api.types';
import TherapeuticAreaItem from '../list/therapeuticAreaItem/TherapeuticAreaItem';
import Title from '../title/Title';

interface AreaGroupPropsType {
  group: TherapeuticType;
}

function AreaGroup({ group }: AreaGroupPropsType): JSX.Element {
  return (
    <div className={styles.group} data-testid="area-group">
      <Title value={group.name} />
      {group.therapeuticAreas.map((item) => (
        <TherapeuticAreaItem key={item.code} item={item} type={group.name} />
      ))}
    </div>
  );
}

export default AreaGroup;
