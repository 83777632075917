import classNames from 'classnames';
import { sanitize } from 'dompurify';

import styles from './ProductSection.module.scss';
import { Monograph } from '../../../services/api/api.types';

interface ProductSectionPropsType {
  monograph: Monograph;
}

function ProductSection({ monograph: { question, answer, showHeader } }: ProductSectionPropsType): JSX.Element {
  const classes = classNames(styles.section, styles[question.toLowerCase()]);

  return (
    <div className={classes}>
      {showHeader && (
        <>
          <b>{question}:</b>
          <br />
        </>
      )}
      <div dangerouslySetInnerHTML={{ __html: sanitize(answer) }} />
    </div>
  );
}

export default ProductSection;
