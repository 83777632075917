import classNames from 'classnames';
import Masonry from 'react-masonry-css';

import styles from './ProductList.module.scss';
import { MonographProduct } from '../../../services/api/api.types';
import ProductCard from '../../productCard/ProductCard';

interface ProductListPropsType {
  products: MonographProduct[] | null;
  isNavigational?: boolean;
}

function ProductList({ products, isNavigational = false }: ProductListPropsType): JSX.Element {
  const classes = classNames(styles.grid, { [styles.grid_indented]: !isNavigational });

  return (
    <Masonry breakpointCols={2} className={classes} columnClassName={styles.grid_column} data-testid="product-list">
      {(products ?? []).map((product) => (
        <ProductCard key={product.name} item={product} />
      ))}
    </Masonry>
  );
}

export default ProductList;
