import { Box } from '@mui/material';
import { ElementType } from 'react';

export interface GenericListItem extends Record<string, unknown> {
  code: number;
}

interface ListPropsType {
  items: GenericListItem[];
  columnCount: number;
  ItemComponent: ElementType;
}

function List({ items, columnCount, ItemComponent }: ListPropsType): JSX.Element {
  const rowCount = Math.ceil(items.length / columnCount);

  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateRows: `repeat(${rowCount}, auto)`,
        gridTemplateColumns: `repeat(${columnCount}, calc((100% / ${columnCount}) - 1rem))`,
        gap: '1rem',
      }}
    >
      {items.map((item) => (
        <ItemComponent key={item.code} item={item} />
      ))}
    </Box>
  );
}

export default List;
