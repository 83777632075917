import { createContext, MutableRefObject, ReactNode, RefObject, useContext, useMemo } from 'react';

import useRefStore, { RefStoreReturnType } from '../hooks/useRefStore';

interface RefProviderPropsType {
  children?: ReactNode;
}

const RefContext = createContext({} as RefStoreReturnType);

function RefProvider({ children = null }: RefProviderPropsType): JSX.Element {
  const { store, addToStore } = useRefStore();
  const contextValue = useMemo(() => ({ store, addToStore }), [addToStore, store]);

  return <RefContext.Provider value={contextValue}>{children}</RefContext.Provider>;
}

export const useRefProviderStore = (): MutableRefObject<RefObject<HTMLDivElement>[]> => {
  const { store } = useContext(RefContext);

  return store;
};

export const useAddToRefStore = (): ((ref: HTMLDivElement | null) => void) => {
  const { addToStore } = useContext(RefContext);

  return addToStore;
};

export default RefProvider;
