import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Tab } from '@mui/material';

import { GroupedResponse } from '../../../services/api/api.types';
import styles from '../SymbolTabs.module.scss';

interface FilteringSymbolTabsPropsType {
  items: GroupedResponse[];
  value: string;
  onChange: (newValue: string) => void;
}

function FilteringSymbolTabs({ items, value, onChange }: FilteringSymbolTabsPropsType): JSX.Element {
  return (
    <TabContext value={value}>
      <TabList
        className={styles.symbolTabList}
        variant="scrollable"
        onChange={(_event, newValue) => onChange(newValue)}
      >
        {items.map((item) => (
          <Tab label={item.symbol} value={item.symbol} key={item.symbol} disabled={item.disabled} />
        ))}
      </TabList>
    </TabContext>
  );
}

export default FilteringSymbolTabs;
