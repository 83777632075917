import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface OffsetProviderPropsType {
  children?: ReactNode;
}

interface ContextType {
  headerHeight: number;
  setHeight: (height: number) => void;
}

const OffsetContext = createContext({} as ContextType);

function OffsetProvider({ children = null }: OffsetProviderPropsType): JSX.Element {
  const [headerHeight, setHeaderHeight] = useState(0);

  const setHeight = (height: number): void => {
    setHeaderHeight(height);
  };

  const contextValue = useMemo(() => ({ headerHeight, setHeight }), [headerHeight]);

  return <OffsetContext.Provider value={contextValue}>{children}</OffsetContext.Provider>;
}

export const useHeaderHeight = (): number => {
  const { headerHeight } = useContext(OffsetContext);

  return headerHeight;
};

export const useSetHeaderHeight = (): ((height: number) => void) => {
  const { setHeight } = useContext(OffsetContext);

  return setHeight;
};

export default OffsetProvider;
