import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './BackButton.module.scss';
import { TabValues } from '../components.constant';

interface BackButtonPropsType {
  to: TabValues;
}

function BackButton({ to }: BackButtonPropsType): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const backTo = (): void => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate({ pathname: '/', hash: `#${to}` });
    }
  };

  return (
    <Button variant="text" startIcon={<ChevronLeftIcon />} className={styles.button} onClick={backTo}>
      Back
    </Button>
  );
}

export default BackButton;
