import { Container } from '@mui/material';
import React from 'react';
import { Ad } from 'react-ad-manager';

import styles from './HomePage.module.scss';
import SearchPanel from '../components/searchPanel/SearchPanel';
import Tabs from '../components/tabs/Tabs';

function HomePage(): JSX.Element {
  return (
    <Container maxWidth="lg" className="minContainer">
      <div className={styles.ad}>
        <Ad
          adUnit="/22858144285/GTOTC-Homepage"
          name="div-gpt-ad-1684424919267-0"
          size={[
            [970, 250],
            [728, 90],
          ]}
          target={[['GTOTC-BannerPosition', ['top_banner']]]}
        />
      </div>
      <SearchPanel />
      <Tabs />
    </Container>
  );
}

export default HomePage;
