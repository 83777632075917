import { getUserRole } from '../services/auth.service';

declare global {
  interface Window {
    adobeDataLayer: unknown[] | undefined;
  }
}

export enum TabClickEvent {
  Products = 'click.products',
  Manufacturers = 'click.manufacturers',
  Therapeutic = 'click.therapeutic',
}

export enum TherapeuticAreaTabClickEvent {
  Overview = 'click.therapeutic.overview',
  Products = 'click.therapeutic.product',
}

export enum PageType {
  Home = 'Home page',
  SearchResult = 'Search result page',
  TherapeuticArea = 'Therapeutic Area page',
  ProductDetails = 'Product details page',
  ErrorPage = 'Error Page',
}

export const setPageViewEvent = (pageType: PageType, role?: string): void => {
  const userRole = role || getUserRole();

  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({ event: 'page.view', page_type: pageType, login_type: userRole });
};

export const setSearchEvent = (
  searchTerm: string,
  numberOfProducts: number,
  numberOfManufacturers: number,
  numberOfTherapeutic: number
): void => {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({
    event: 'search.button.click',
    search_term: searchTerm,
    products_quantity: numberOfProducts,
    manufacturers_quantity: numberOfManufacturers,
    therapeutic_quantity: numberOfTherapeutic,
  });
};

export const setTabEvent = (eventType: TabClickEvent): void => {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({ event: eventType });
};

export const setTherapeuticAreaTabEvent = (eventType: TherapeuticAreaTabClickEvent, area: string): void => {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({ event: eventType, therapeutic_area: area });
};
