import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Button, Divider, Menu, MenuItem } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useRef, useState, MouseEvent } from 'react';
import { useIsFetching } from 'react-query';

import styles from './Header.module.scss';
import configuration from '../../config';
import { useSetHeaderHeight } from '../../utils/OffsetProvider';
import { FORBIDDEN_STATUS_CODE, UNAUTHORIZED_STATUS_CODE } from '../components.constant';

const { REACT_APP_AUTH_URL } = configuration;

function Header(): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const setHeaderHeight = useSetHeaderHeight();
  const isInitialFetching = !!useIsFetching({
    predicate: (query) => ['auth-token-details', 'login', 'brands'].some((key) => key === query.queryKey),
  });

  const logoClasses = classNames(styles.logoLink, { [styles.logoLink_disabled]: isInitialFetching });

  const isUnauthorizedErrorPage = window.location.search.includes(UNAUTHORIZED_STATUS_CODE.toString());
  const isForbiddenErrorPage = window.location.search.includes(FORBIDDEN_STATUS_CODE.toString());

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const logout = (): void => {
    window.location.assign(`${configuration.REACT_APP_AUTH_URL}/logout`);
  };

  const openDocumentByUrl = (url: string): void => {
    window.open(url, '_blank');
    handleClose();
  };

  useEffect(() => {
    setHeaderHeight(ref.current?.offsetHeight ?? 0);
  }, [setHeaderHeight]);

  return (
    <header className={styles.header} ref={ref}>
      <a href={REACT_APP_AUTH_URL} className={logoClasses} title="C+D Data logo">
        <img alt="logo" src="/logo-with-text.svg" className={styles.logo} />
      </a>
      <div className={styles.tabs}>
        <a href={REACT_APP_AUTH_URL} className={isInitialFetching ? styles.disabled : ''} data-text="C+D Data">
          C+D Data
        </a>
        <a href="/" className={styles.selected} data-text="Guide to OTC">
          Guide to OTC
        </a>
      </div>
      {!isUnauthorizedErrorPage && (
        <div className={styles.menuContainer}>
          <Button className={styles.menuButton} startIcon={<AccountCircleOutlinedIcon />} onClick={handleClick}>
            My Account
          </Button>
          <Menu disableScrollLock anchorEl={anchorEl} open={!!anchorEl} className={styles.menu} onClose={handleClose}>
            <MenuItem onClick={logout}>Sign out</MenuItem>
            <Divider />
            {!isForbiddenErrorPage && (
              <MenuItem onClick={() => openDocumentByUrl('/user-guide.pdf')}>User Guide</MenuItem>
            )}
            <MenuItem onClick={() => openDocumentByUrl('/FAQs.pdf')}>FAQ</MenuItem>
          </Menu>
        </div>
      )}
    </header>
  );
}

export default Header;
