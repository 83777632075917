import { useScrollToTop } from '../../hooks/useScrollToTop';
import { BrandResponse } from '../../services/api/api.types';
import { SEARCH_RESULT_COLUMN_COUNT } from '../components.constant';
import BrandGroupedList from '../groupedList/brandGroupedList/BrandGroupedList';
import SymbolTabs from '../symbolTabs/SymbolTabs';

interface ProductsSearchResultPropsTypes {
  searchResult: BrandResponse[];
}

function ProductsSearchResult({ searchResult }: ProductsSearchResultPropsTypes): JSX.Element {
  const filteredResult = searchResult.filter((item) => !item.disabled);

  useScrollToTop();

  return (
    <>
      <SymbolTabs items={searchResult} />
      <BrandGroupedList items={filteredResult} columnCount={SEARCH_RESULT_COLUMN_COUNT} />
    </>
  );
}

export default ProductsSearchResult;
