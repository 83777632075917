import { Ad } from 'react-ad-manager';

import Description from './description/Description';
import styles from './Overview.module.scss';
import Section from './section/Section';
import Contents from './сontents/Contents';
import { OverviewSection } from '../../services/api/api.types';
import { useAddToRefStore } from '../../utils/RefProvider';
import { Content } from '../components.constant';

interface OverviewPropsType {
  sections: OverviewSection[];
  areaName: string;
}

function Overview({ sections, areaName }: OverviewPropsType): JSX.Element {
  const addToStore = useAddToRefStore();

  const contentItems = sections.map(({ name, code }) => ({ code, name }));

  if (!sections.length) {
    return <span>The Overview will be updated soon</span>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        {sections.map(({ name, answer, code }) =>
          name === Content.Overview ? (
            <Description key={code} answer={answer} ref={(ref) => addToStore(ref)} />
          ) : (
            <Section key={code} name={name} answer={answer} ref={(ref) => addToStore(ref)} />
          )
        )}
      </div>
      <div className={styles.navigation}>
        <Contents items={contentItems} />
        <div className={styles.sideAd}>
          <Ad
            adUnit="/22858144285/GTOTC-TherapeuticAreas"
            name="side-banner"
            size={[
              [
                [1440, 720],
                [
                  [300, 250],
                  [120, 600],
                  [160, 600],
                  [300, 600],
                ],
              ],
              [[0, 0], []],
            ]}
            target={[['TherapeuticArea', [areaName]]]}
          />
        </div>
      </div>
    </div>
  );
}

export default Overview;
