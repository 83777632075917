import { useQuery, UseQueryResult } from 'react-query';

import { STALE_TIME } from './hooks.constant';
import { getTherapeuticTypes } from '../services/api/api.service';
import { TherapeuticType } from '../services/api/api.types';

const useTherapeuticTypesData = (): UseQueryResult<TherapeuticType[]> =>
  useQuery('therapeutic_types', () => getTherapeuticTypes(), {
    staleTime: STALE_TIME,
  });

export default useTherapeuticTypesData;
