import axios from 'axios';

import {
  AuthTokenResponse,
  BrandDetails,
  BrandResponse,
  GenericResponse,
  GroupedResponse,
  LoginResponse,
  ManufacturerResponse,
  MonographProduct,
  SearchResult,
  TherapeuticAreaOverview,
  TherapeuticAreaProducts,
  TherapeuticType,
} from './api.types';
import { instance } from './instance';
import configuration, { ENDPOINT, THERAPEUTIC_AREA_ENTITY } from '../../config';

const { AUTH_TOKEN, LOGIN, MANUFACTURER, BRAND, THERAPEUTIC_AREA, SEARCH } = ENDPOINT;
const { PRODUCTS, SYMBOLS, PROMOTED } = THERAPEUTIC_AREA_ENTITY;

export const getWebToken = (): Promise<AuthTokenResponse> =>
  axios
    .get<AuthTokenResponse>(`${configuration.REACT_APP_AUTH_URL}${AUTH_TOKEN}`, { withCredentials: true })
    .then((response) => response.data);

export const login = (): Promise<LoginResponse> => instance.get<LoginResponse>(LOGIN).then((response) => response.data);

export const getManufacturers = (): Promise<ManufacturerResponse[]> =>
  instance.get<GenericResponse<ManufacturerResponse[]>>(MANUFACTURER).then((response) => response.data.response);

export const getBrands = (): Promise<BrandResponse[]> =>
  instance.get<GenericResponse<BrandResponse[]>>(BRAND).then((response) => response.data.response);

export const getTherapeuticTypes = (): Promise<TherapeuticType[]> =>
  instance.get<GenericResponse<TherapeuticType[]>>(THERAPEUTIC_AREA).then((response) => response.data.response);

export const getTherapeuticAreaById = (id: string | undefined = ''): Promise<TherapeuticAreaOverview> =>
  instance
    .get<GenericResponse<TherapeuticAreaOverview>>(`${THERAPEUTIC_AREA}/${id}`)
    .then((response) => response.data.response);

export const getDataForAreaProductsSymbolTabs = (id: string | undefined = ''): Promise<GroupedResponse[]> =>
  instance
    .get<GenericResponse<GroupedResponse[]>>(`${THERAPEUTIC_AREA}/${id}/${SYMBOLS}`)
    .then((response) => response.data.response);

export const getTherapeuticAreaProducts = (
  filterBy: string,
  id: string | undefined = ''
): Promise<TherapeuticAreaProducts> =>
  instance
    .get<GenericResponse<TherapeuticAreaProducts>>(`${THERAPEUTIC_AREA}/${id}/${PRODUCTS}`, {
      params: { filterBy },
    })
    .then((response) => response.data.response);

export const getTherapeuticAreaPromotedProduct = (id: string | undefined = ''): Promise<MonographProduct | null> =>
  instance
    .get<GenericResponse<MonographProduct>>(`${THERAPEUTIC_AREA}/${id}/${PROMOTED}`)
    .then((response) => response.data.response);

export const getBrandDetailsById = (id: number): Promise<BrandDetails> =>
  instance.get<GenericResponse<BrandDetails>>(`${BRAND}/${id}`).then((response) => response.data.response);

export const getSearchResults = async (searchString: string): Promise<SearchResult> =>
  instance
    .get<GenericResponse<SearchResult>>(SEARCH, { params: { searchTerm: searchString } })
    .then((response) => response.data.response);
