import { Backdrop } from '@mui/material';

import styles from './BackdropLoader.module.scss';
import Loader from '../loader/Loader';

function BackdropLoader(): JSX.Element {
  return (
    <Backdrop open className={styles.backdrop}>
      <Loader />
    </Backdrop>
  );
}

export default BackdropLoader;
