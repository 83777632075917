import { NavLink } from 'react-router-dom';

import styles from './TherapeuticAreaItem.module.scss';
import { ROUTER_URL } from '../../../router.constant';
import { BaseEntity } from '../../../services/api/api.types';
import getTherapeuticTypePath from '../../../utils/getTherapeuticTypePath';

interface TherapeuticAreaItemPropsType {
  item: BaseEntity<string>;
  type: string;
}

function TherapeuticAreaItem({ item, type }: TherapeuticAreaItemPropsType): JSX.Element {
  const path = getTherapeuticTypePath(type, item.code);

  return (
    <NavLink to={`/${ROUTER_URL.THERAPEUTIC_TYPES}/${path}`} className={styles.item}>
      {item.name}
    </NavLink>
  );
}

export default TherapeuticAreaItem;
