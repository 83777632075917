import { Ad } from 'react-ad-manager';

import styles from './PromotedProduct.module.scss';
import { MonographProduct } from '../../services/api/api.types';
import ProductCard from '../productCard/ProductCard';

interface PromotedProductPropsType {
  product: MonographProduct;
}

function PromotedProduct({ product }: PromotedProductPropsType): JSX.Element {
  const { code } = product;

  return (
    <div className={styles.container}>
      <ProductCard isPromotedProduct item={product} />
      <div className={styles.ad}>
        <Ad
          adUnit="/22858144285/GTOTC-ProductsSite"
          name={`promoted-product-card-${code}`}
          size={[
            [320, 480],
            [250, 360],
          ]}
          target={[['GTOTC-ProductCode', [code.toString()]]]}
        />
      </div>
    </div>
  );
}

export default PromotedProduct;
