import { CircularProgress } from '@mui/material';
import classNames from 'classnames';

import styles from './Loader.module.scss';

interface LoaderPropsType {
  fullPage?: boolean;
}

function Loader({ fullPage = false }: LoaderPropsType): JSX.Element {
  const classes = classNames(styles.container, { [styles.fullPage]: fullPage });

  return (
    <div className={classes}>
      <CircularProgress />
    </div>
  );
}

export default Loader;
