import styles from './BrandInfo.module.scss';
import EmailLink from '../../emailLink/EmailLink';

interface BrandInfoPropsType {
  description: string;
  email: string;
}

function BrandInfo({ description, email }: BrandInfoPropsType): JSX.Element {
  return (
    <span className={styles.container} data-testid="brand-info">
      <span>{description}</span>
      {email && (
        <>
          {' '}
          &bull; <EmailLink email={email} />
        </>
      )}
    </span>
  );
}

export default BrandInfo;
