import sortBy from 'lodash/sortBy';
import { Ad } from 'react-ad-manager';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import AreaTabs from './areaTabs/AreaTabs';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import useSetTherapeuticAreaTabToAnalytics from '../../hooks/useSetTherapeuticAreaTabToAnalytics';
import { getTherapeuticAreaById } from '../../services/api/api.service';
import BackButton from '../backButton/BackButton';
import BackdropLoader from '../backdrop/BackdropLoader';
import { TabValues } from '../components.constant';
import Title from '../title/Title';

function Area(): JSX.Element {
  const { id } = useParams();
  const { isLoading, data } = useQuery(['area', id], () => getTherapeuticAreaById(id), { cacheTime: 0 });
  const sortedSections = sortBy(data?.sections, (section) => section.sortOrder);

  useSetTherapeuticAreaTabToAnalytics(data?.name ?? '');

  useScrollToTop();

  if (isLoading) {
    return <BackdropLoader />;
  }

  return (
    <div className="entityContainer" key={id}>
      {data && (
        <>
          <div className="ad">
            <Ad
              adUnit="/22858144285/GTOTC-TherapeuticAreas"
              name="top-banner"
              size={[
                [
                  [1440, 720],
                  [
                    [970, 250],
                    [728, 90],
                    [970, 90],
                  ],
                ],
                [[0, 0], []],
              ]}
              target={[['TherapeuticArea', [data.name]]]}
            />
          </div>
          <BackButton to={TabValues.Areas} />
          <Title value={data.therapeuticType.name} className="pageTitle" />
          <Title value={data.name} className="areaSubTitle" />
          <AreaTabs overviewSections={sortedSections} areaName={data.name} />
        </>
      )}
    </div>
  );
}

export default Area;
