import styles from './Title.module.scss';

interface GroupTitlePropsType {
  value: string;
  className?: string;
}

function Title({ value, className = '' }: GroupTitlePropsType): JSX.Element {
  return <b className={styles[`${className}`]}>{value}</b>;
}

export default Title;
