import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface HandleFunction<T> {
  (_event: SyntheticEvent, newValue: T): void;
}

export const useHashNavigation = <T>(initValue: T): [T, HandleFunction<T>] => {
  const navigate = useNavigate();
  const { hash, search } = useLocation();

  const hashValue = useMemo(() => (hash ? (hash.slice(1) as T) : initValue), [hash, initValue]);
  const navigateToHash = useCallback(
    (value: T): void => {
      navigate({ hash: `#${value}`, search }, { replace: true });
    },
    [navigate, search]
  );

  const handleChange = (_event: SyntheticEvent, newValue: T): void => {
    navigateToHash(newValue);
  };

  useEffect(() => {
    if (!hash) {
      navigateToHash(initValue);
    }
  }, [hash, initValue, navigateToHash]);

  return [hashValue, handleChange];
};
