import { useScrollToTop } from '../../hooks/useScrollToTop';
import { ManufacturerResponse } from '../../services/api/api.types';
import { SEARCH_RESULT_COLUMN_COUNT } from '../components.constant';
import ManufacturerGroupedList from '../groupedList/manufacturerGroupedList/ManufacturerGroupedList';
import SymbolTabs from '../symbolTabs/SymbolTabs';

interface ManufacturersSearchResultPropsType {
  searchResult: ManufacturerResponse[];
}

function ManufacturersSearchResult({ searchResult }: ManufacturersSearchResultPropsType): JSX.Element {
  const filteredResult = searchResult.filter((item) => !item.disabled);

  useScrollToTop();

  return (
    <>
      <SymbolTabs items={searchResult} />
      <ManufacturerGroupedList items={filteredResult} columnCount={SEARCH_RESULT_COLUMN_COUNT} />
    </>
  );
}

export default ManufacturersSearchResult;
