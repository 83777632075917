import { Ad } from 'react-ad-manager';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import BrandInfo from './brandInfo/BrandInfo';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import { getBrandDetailsById } from '../../services/api/api.service';
import { useSetTableSettings } from '../../utils/ProductTableSettingsProvider';
import BackButton from '../backButton/BackButton';
import BackdropLoader from '../backdrop/BackdropLoader';
import { TabValues } from '../components.constant';
import ProductList from '../products/productList/ProductList';
import Title from '../title/Title';

function Brand(): JSX.Element {
  const { id } = useParams();
  const setTableSettings = useSetTableSettings();

  const { isLoading, data } = useQuery(['brand', id], () => getBrandDetailsById(Number(id)), {
    cacheTime: 0,
    onSuccess: ({ productHeaders }) => setTableSettings(productHeaders),
  });

  useScrollToTop();

  if (isLoading) {
    return <BackdropLoader />;
  }

  return (
    <div className="entityContainer" key={id}>
      {data && (
        <>
          <div className="ad">
            <Ad
              adUnit="/22858144285/GTOTC-ProductsSite"
              name="top-banner"
              size={[
                [
                  [1440, 720],
                  [
                    [970, 250],
                    [728, 90],
                    [970, 90],
                  ],
                ],
                [[0, 0], []],
              ]}
              target={[['BrandName', [data.brand]]]}
            />
          </div>
          <BackButton to={TabValues.Products} />
          <Title value="Products" className="pageTitle" />
          <Title value={data.brand} className="brandTitle" />
          <BrandInfo description={data.manufacturer} email={data.email} />
          <ProductList products={data.monographProducts} />
        </>
      )}
    </div>
  );
}

export default Brand;
