import React from 'react';
import { Ad } from 'react-ad-manager';

import { BrandResponse } from '../../../services/api/api.types';
import BrandItem from '../../list/brandItem/BrandItem';
import List from '../../list/List';
import Symbol from '../../symbol/Symbol';
import styles from '../GroupedList.module.scss';

interface BrandGroupedListPropsType {
  items: BrandResponse[];
  columnCount: number;
}

function BrandGroupedList({ items, columnCount }: BrandGroupedListPropsType): JSX.Element {
  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <div id={item.symbol} key={item.symbol} className={styles.list} data-testid="brand-grouped-list">
          <Symbol symbol={item.symbol} disabled={item.disabled} />
          <div className={styles.adContainer}>
            <Ad
              adUnit="/22858144285/GTOTC-Homepage/GTOTC-HP-Products"
              name={`banner-${index + 1}`}
              size={[728, 90]}
              target={[['CTOTC-AlphaNum', [item.symbol]]]}
            />
            <List items={item.brands} columnCount={columnCount} ItemComponent={BrandItem} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default BrandGroupedList;
