interface ScrollToElementReturnType {
  (target: HTMLElement, topOffset: number): void;
}

const useScrollToElement =
  (): ScrollToElementReturnType =>
  (target: HTMLElement, topOffset: number): void => {
    const elementYPosition = target.getBoundingClientRect().top;

    window.scrollTo({ behavior: 'smooth', top: elementYPosition + window.scrollY - topOffset });
  };

export default useScrollToElement;
