import Content from '../components/content/Content';
import Loader from '../components/loader/Loader';
import CollapsibleSideMenu from '../components/sideMenu/collapsibleSideMenu/CollapsibleSideMenu';
import useBrandsData from '../hooks/useBrandsData';
import useSetPageEventOnIdChange from '../hooks/useSetPageEventOnIdChange';
import useSideMenu from '../hooks/useSideMenu';
import { PageType } from '../utils/analyticsDataLayer';

function BrandsPage(): JSX.Element {
  const [isSideMenuOpen, switchSideMenuState] = useSideMenu();
  const { isLoading, data = [] } = useBrandsData();

  useSetPageEventOnIdChange(PageType.ProductDetails);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="pageContainer">
      <CollapsibleSideMenu list={data} isOpen={isSideMenuOpen} onMenuIconClick={switchSideMenuState} />
      <Content isMenuOpen={isSideMenuOpen} />
    </div>
  );
}

export default BrandsPage;
