import classNames from 'classnames';
import useScrollSpy from 'react-use-scrollspy';

import styles from './Contents.module.scss';
import useScrollToElement from '../../../hooks/useScrollToElement';
import { OverviewSection } from '../../../services/api/api.types';
import { useHeaderHeight } from '../../../utils/OffsetProvider';
import { useRefProviderStore } from '../../../utils/RefProvider';
import { SCROLL_SPY_OFFSET } from '../../components.constant';
import Title from '../../title/Title';

interface ContentsPropsType {
  items: Omit<OverviewSection, 'answer' | 'sortOrder'>[];
}

function Contents({ items }: ContentsPropsType): JSX.Element {
  const store = useRefProviderStore();
  const headerHeight = useHeaderHeight();
  const scrollTo = useScrollToElement();

  const activeSection = useScrollSpy({
    sectionElementRefs: store.current,
    offsetPx: SCROLL_SPY_OFFSET,
  });

  const scroll = (index: number): void => {
    const target = store.current[index].current;

    if (target) {
      scrollTo(target, headerHeight);
    }
  };

  const getClassNames = (index: number): string =>
    classNames(styles.item, { [styles.item_active]: activeSection === index });

  return (
    <div className={styles.contents}>
      <Title value="Contents" className="contentTitle" />
      <div className={styles.contents__list}>
        {items.map(({ code, name }, index) => (
          <button key={code} type="button" className={getClassNames(index)} onClick={() => scroll(index)}>
            {name}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Contents;
