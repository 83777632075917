import classNames from 'classnames';

import styles from './Symbol.module.scss';

interface SymbolPropsType {
  symbol: string;
  disabled: boolean;
}

function Symbol({ symbol, disabled }: SymbolPropsType): JSX.Element {
  const classes = classNames(styles.symbol, { [styles.symbol_disabled]: disabled });

  return <span className={classes}>{symbol}</span>;
}

export default Symbol;
