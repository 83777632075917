import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { PageType, setPageViewEvent } from '../utils/analyticsDataLayer';

const useSetPageEventOnIdChange = (pageType: PageType): void => {
  const { id } = useParams();

  const ref = useRef('');

  useEffect(() => {
    if (id && ref.current !== id) {
      ref.current = id;

      setPageViewEvent(pageType);
    }
  }, [id, pageType]);
};

export default useSetPageEventOnIdChange;
