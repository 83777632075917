import styles from './ManufacturerItem.module.scss';
import { Manufacturer } from '../../../services/api/api.types';
import EmailLink from '../../emailLink/EmailLink';

interface ManufacturerItemPropsType {
  item: Manufacturer;
}

function ManufacturerItem({ item: { name, phone, email } }: ManufacturerItemPropsType): JSX.Element {
  return (
    <div className={styles.container}>
      {name}
      <ul className={styles.list}>
        {phone ? <li>{phone}</li> : null}
        {email ? (
          <li>
            <EmailLink email={email} />
          </li>
        ) : null}
      </ul>
    </div>
  );
}

export default ManufacturerItem;
