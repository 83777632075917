import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './Content.module.scss';

interface ContentPropsType {
  isMenuOpen: boolean;
}

function Content({ isMenuOpen }: ContentPropsType): JSX.Element {
  const firstRender = useRef(true);
  const classes = classNames(styles.content, { [styles.content_expanded]: isMenuOpen });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;

      window.scrollTo(0, 0);
    }
  });

  return (
    <div className={classes} data-testid="content">
      <Outlet />
    </div>
  );
}

export default Content;
