import { Ad } from 'react-ad-manager';

import CardLinks from './cardList/CardLinks';
import styles from './ProductCard.module.scss';
import ProductSection from './productSection/ProductSection';
import ProductTable from './productTable/ProductTable';
import { MonographProduct } from '../../services/api/api.types';
import { getGroupedSections } from '../../utils/monograph.helper';

interface ProductCardPropsType {
  item: MonographProduct;
  isPromotedProduct?: boolean;
}

function ProductCard({
  item: { code, name, manufacturer, links, products, monographs },
  isPromotedProduct = false,
}: ProductCardPropsType): JSX.Element {
  const [sectionsBeforeImageCarousel, restSections] = getGroupedSections(monographs);

  return (
    <div className={styles.card} data-testid="product-card">
      <div className={styles.card__container}>
        <span className={styles.card__name}>{name}</span>
        <span className={styles.card__brand}>{manufacturer}</span>
      </div>
      {sectionsBeforeImageCarousel.map((monograph) => (
        <ProductSection key={monograph.question} monograph={monograph} />
      ))}
      {!isPromotedProduct && (
        <div className={styles.ad}>
          <Ad
            adUnit="/22858144285/GTOTC-ProductsSite"
            name={`product-card-${code}`}
            size={[
              [320, 480],
              [250, 360],
            ]}
            target={[['GTOTC-ProductCode', [code.toString()]]]}
          />
        </div>
      )}
      {restSections.map((monograph) => (
        <ProductSection key={monograph.question} monograph={monograph} />
      ))}
      {!!products.length && <ProductTable products={products} />}
      {!!links.length && <CardLinks links={links} />}
    </div>
  );
}

export default ProductCard;
