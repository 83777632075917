import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import styles from './CollapsibleMenuGroup.module.scss';
import useScrollToActiveLink from '../../../hooks/useScrollToActiveLink';
import { BrandResponse } from '../../../services/api/api.types';
import MenuLink from '../menuLink/MenuLink';

export interface CollapsibleMenuGroupPropsType {
  group: BrandResponse;
  isLastGroup?: boolean;
}

function CollapsibleMenuGroup({ group, isLastGroup = false }: CollapsibleMenuGroupPropsType): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const groupRef = useRef<HTMLDivElement>(null);
  const getActiveLink = useScrollToActiveLink(groupRef);
  const listRef = useRef<HTMLDivElement>(null);

  const titleStyles = classNames(styles.groupTitle, { [styles.groupTitle_disabled]: group.disabled });

  useEffect(() => {
    if (getActiveLink()) {
      setIsOpen(true);
    }
  }, [getActiveLink]);

  const handleClick = (): void => {
    setIsOpen(!isOpen);

    if (isLastGroup && !isOpen) {
      setTimeout(() => listRef.current?.lastElementChild?.scrollIntoView(), 500);
    }
  };

  return (
    <div className={styles.group} ref={groupRef} data-testid="collapsible-menu-group">
      <span role="presentation" className={titleStyles} onClick={handleClick}>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
        {group.symbol}
      </span>
      <Collapse in={isOpen} timeout="auto" className={styles.collapsableList} data-testid="collapse">
        <div className={styles.container} ref={listRef}>
          {group.brands.map((item) => (
            <MenuLink key={item.code} item={item} />
          ))}
        </div>
      </Collapse>
    </div>
  );
}

export default CollapsibleMenuGroup;
