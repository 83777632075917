import { sanitize } from 'dompurify';
import { ForwardedRef, forwardRef } from 'react';

import styles from './Section.module.scss';

interface SectionPropsType {
  name: string;
  answer: string;
}

function SectionWithRef({ name, answer }: SectionPropsType, ref: ForwardedRef<null>): JSX.Element {
  return (
    <div className={styles.container}>
      <div ref={ref}>
        <b className={styles.title}>{name}</b>
      </div>
      <div dangerouslySetInnerHTML={{ __html: sanitize(answer) }} />
    </div>
  );
}

const Section = forwardRef(SectionWithRef);

export default Section;
