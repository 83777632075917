import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Tab } from '@mui/material';
import { SyntheticEvent, useState, useRef } from 'react';

import styles from './SymbolTabs.module.scss';
import useScrollToElement from '../../hooks/useScrollToElement';
import { BrandResponse, GroupedResponse, ManufacturerResponse } from '../../services/api/api.types';
import { useHeaderHeight } from '../../utils/OffsetProvider';
import { FIRST_SYMBOL } from '../components.constant';

interface SymbolTabsPropsType {
  items: ManufacturerResponse[] | BrandResponse[];
}

function SymbolTabs({ items }: SymbolTabsPropsType): JSX.Element {
  const selectedTab = (items as GroupedResponse[]).find((item) => !item.disabled)?.symbol ?? FIRST_SYMBOL;
  const [value, setValue] = useState(selectedTab);
  const tabRef = useRef<HTMLDivElement>(null);
  const headerHeight = useHeaderHeight();
  const scrollTo = useScrollToElement();

  const handleChange = (event: SyntheticEvent, newValue: string): void => {
    const target = document.getElementById(newValue);

    if (target) {
      const elementPaddingTop = parseInt(window.getComputedStyle(target).getPropertyValue('padding-top'), 10);
      const tabsHeight = Number(tabRef.current?.offsetHeight);

      scrollTo(target, headerHeight + tabsHeight - elementPaddingTop);
    }

    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList variant="scrollable" className={styles.symbolTabList} ref={tabRef} onChange={handleChange}>
        {items.map((item) => (
          <Tab
            label={item.symbol}
            value={item.symbol}
            key={item.symbol}
            disabled={item.disabled}
            data-text={item.symbol}
          />
        ))}
      </TabList>
    </TabContext>
  );
}

export default SymbolTabs;
