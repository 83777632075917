import ClearIcon from '@mui/icons-material/Clear';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import styles from './SearchInput.module.scss';
import { ROUTER_URL } from '../../../router.constant';
import { MIN_SEARCH_LENGTH } from '../../components.constant';

function SearchInput(): JSX.Element {
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const [inputValue, setInputValue] = useState(search.get('searchValue') ?? '');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      setIsError(inputValue.trim().length < MIN_SEARCH_LENGTH);
    }
  }, [inputValue, isSubmitted]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
  };

  const clearInput = (): void => {
    setInputValue('');
    setIsSubmitted(false);
    setIsError(false);
    navigate('/');
  };

  const handleSubmit = (): void => {
    const trimmedValue = inputValue.trim();

    setIsSubmitted(true);

    if (trimmedValue.length >= MIN_SEARCH_LENGTH) {
      setInputValue(trimmedValue);
      navigate({
        pathname: `/${ROUTER_URL.SEARCH_RESULTS}`,
        search: createSearchParams({ searchValue: trimmedValue }).toString(),
      });
    }
  };

  return (
    <div className={styles.area}>
      <span className={styles.label}>Products, manufacturers and therapeutic areas</span>
      {isError && (
        <div className={styles.error}>
          <ErrorOutlineIcon />
          <span>Enter at least {MIN_SEARCH_LENGTH} characters</span>
        </div>
      )}
      <div className={styles.container}>
        <TextField
          error={isError}
          className={styles.input}
          value={inputValue}
          placeholder="Minimum 3 characters"
          data-testid="input"
          InputProps={{
            endAdornment: inputValue ? (
              <InputAdornment position="end">
                <IconButton data-testid="clear-button" onClick={clearInput}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          onChange={handleInputChange}
          onKeyDown={(event) => (event.code === 'Enter' || event.code === 'NumpadEnter' ? handleSubmit() : null)}
        />
        <IconButton size="large" className={styles.iconButton} data-testid="search-button" onClick={handleSubmit}>
          <SearchIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default SearchInput;
