import React from 'react';
import { useQuery } from 'react-query';

import { STALE_TIME } from '../../hooks/hooks.constant';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import { getManufacturers } from '../../services/api/api.service';
import { MANUFACTURER_COLUMN_COUNT } from '../components.constant';
import ManufacturerGroupedList from '../groupedList/manufacturerGroupedList/ManufacturerGroupedList';
import Loader from '../loader/Loader';
import SymbolTabs from '../symbolTabs/SymbolTabs';

function Manufacturers(): JSX.Element {
  const { isLoading, data = [] } = useQuery('manufacturers', () => getManufacturers(), {
    staleTime: STALE_TIME,
  });

  useScrollToTop();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <SymbolTabs items={data} />
      <ManufacturerGroupedList items={data} columnCount={MANUFACTURER_COLUMN_COUNT} />
    </>
  );
}

export default Manufacturers;
