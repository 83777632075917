import { useRef } from 'react';

import styles from './MenuGroup.module.scss';
import useScrollToActiveLink from '../../../hooks/useScrollToActiveLink';
import { TherapeuticType } from '../../../services/api/api.types';
import Title from '../../title/Title';
import MenuLink from '../menuLink/MenuLink';

interface MenuGroupPropsType {
  group: TherapeuticType;
}

function MenuGroup({ group }: MenuGroupPropsType): JSX.Element {
  const groupRef = useRef<HTMLDivElement>(null);

  useScrollToActiveLink(groupRef);

  return (
    <div className={styles.group} ref={groupRef} data-testid="menu-group">
      <Title value={group.name} className="navigationTitle" />
      {group.therapeuticAreas.map((item) => (
        <MenuLink key={item.code} item={item} type={group.name} />
      ))}
    </div>
  );
}

export default MenuGroup;
