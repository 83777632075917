import { Monograph } from '../services/api/api.types';

const DIVIDE_INDEX = 2;

export const getGroupedSections = (monographs: Monograph[]): [Monograph[], Monograph[]] => {
  const sectionsBeforeImageCarousel = monographs.filter((monograph) => monograph.sortOrder <= DIVIDE_INDEX);
  const restSections = monographs.filter((monograph) => monograph.sortOrder > DIVIDE_INDEX);

  return [sectionsBeforeImageCarousel, restSections];
};
