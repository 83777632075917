import { Button, Container } from '@mui/material';
import axios from 'axios';
import { useEffect, useRef } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useLocation, useNavigate, useRouteError, useSearchParams } from 'react-router-dom';

import styles from './ErrorPage.module.scss';
import {
  DEFAULT_MESSAGE,
  ErrorCode,
  FORBIDDEN_STATUS_CODE,
  NOT_FOUND_STATUS_CODE,
  SERVER_ERROR_STATUS_CODE,
  UNAUTHORIZED_STATUS_CODE,
  UNHANDLED_ERROR,
} from '../components/components.constant';
import EmailLink from '../components/emailLink/EmailLink';
import useTriggerOnceOnMount from '../hooks/useTriggerOnceOnMount';
import { getNotAllowedFlag, getWebToken, redirectToLoginPage } from '../services/auth.service';
import { PageType, setPageViewEvent } from '../utils/analyticsDataLayer';

interface RedirectButtonPropsType {
  label: string;
}

function RedirectButton({ label }: RedirectButtonPropsType): JSX.Element {
  return (
    <Button variant="contained" className={styles.button} onClick={() => redirectToLoginPage()}>
      {label}
    </Button>
  );
}

interface ErrorPagePropsType {
  statusCode: number | undefined;
}

const getErrorStatusCode = (error: unknown): number | undefined => {
  if (axios.isAxiosError(error)) {
    return error.response?.status;
  }

  return undefined;
};

interface FallbackPropsType extends FallbackProps {
  isRouterError?: boolean;
}

export function Fallback({
  error = undefined,
  resetErrorBoundary,
  isRouterError = false,
}: FallbackPropsType): JSX.Element {
  const routerError = useRouteError();
  const statusCode = getErrorStatusCode(isRouterError ? routerError : error);
  const { pathname } = useLocation();
  const errorLocation = useRef(pathname);

  useEffect(() => {
    if (pathname !== errorLocation.current) {
      resetErrorBoundary();
    }
  }, [pathname, resetErrorBoundary]);

  return <ErrorPage statusCode={statusCode} />;
}

export function ErrorPageByUrl(): JSX.Element {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const statusCode = Number(search.get('statusCode'));
  const isNotAllowed = !!getNotAllowedFlag();

  useEffect(() => {
    if (isNotAllowed && statusCode !== FORBIDDEN_STATUS_CODE) {
      navigate({ search: `statusCode=${FORBIDDEN_STATUS_CODE}` });
    }
  });

  useEffect(() => {
    if (!getWebToken() && !isNotAllowed && statusCode !== UNAUTHORIZED_STATUS_CODE) {
      navigate({ search: `statusCode=${UNAUTHORIZED_STATUS_CODE}` });
    }
  });

  return <ErrorPage statusCode={statusCode} />;
}

function ErrorPage({ statusCode }: ErrorPagePropsType): JSX.Element {
  useTriggerOnceOnMount(() => setPageViewEvent(PageType.ErrorPage));

  const getDescription = (): JSX.Element => {
    switch (statusCode) {
      case UNAUTHORIZED_STATUS_CODE:
        return (
          <>
            <p>You need to be signed in with a premium subscription to view this page.</p>
            <RedirectButton label="Sign in" />
            <p>
              <b>Forgotten your password?</b>
              <br />
              Contact our subscription team who will be able to provide assistance. <br />
              E. <EmailLink email="info@subs.chemistanddruggist.co.uk" /> <br />
              T. 0330 333 0166
            </p>
          </>
        );
      case FORBIDDEN_STATUS_CODE:
        return (
          <>
            <p>Unfortunately, you don’t currently have the right subscription to access the Guide to OTC. </p>
            <p>
              A premium subscription is required to access this page, to upgrade your account or find out more details
              click here:
            </p>
            <Button
              variant="contained"
              className={styles.button}
              href="https://www.citeline.com/en/products-services/commercialization/chemist-and-druggist-data/c-and-d-data---subscription"
              target="_blank"
            >
              Subscription options
            </Button>
            <p>
              Contact us by phone on +44 (0)330 333 0166 or email:{' '}
              <EmailLink email="info@subs.chemistanddruggist.co.uk" /> quoting UPREM.
            </p>
            <p>We have a variety of subscription options, so you can decide the best one for you or your business.</p>
          </>
        );
      case NOT_FOUND_STATUS_CODE:
        return (
          <p>
            Check that you typed the address correctly, go back to your previous page or visit the{' '}
            <a href="/" className={styles.link}>
              OTC homepage
            </a>
            .
          </p>
        );
      case SERVER_ERROR_STATUS_CODE:
        return (
          <p>
            The server encountered a problem and couldn’t load the page. <br />
            Try refreshing the page.
          </p>
        );
      default:
        return <p>{DEFAULT_MESSAGE}</p>;
    }
  };

  return (
    <Container maxWidth="lg" className={styles.container}>
      <span className={styles.title}>
        {statusCode && ErrorCode[statusCode] ? ErrorCode[statusCode] : UNHANDLED_ERROR}
      </span>
      {getDescription()}
    </Container>
  );
}
