import Content from '../components/content/Content';
import Loader from '../components/loader/Loader';
import FlatSideMenu from '../components/sideMenu/flatSideMenu/FlatSideMenu';
import useSetPageEventOnIdChange from '../hooks/useSetPageEventOnIdChange';
import useSideMenu from '../hooks/useSideMenu';
import useTherapeuticTypesData from '../hooks/useTherapeuticTypesData';
import { PageType } from '../utils/analyticsDataLayer';

function TherapeuticAreaPage(): JSX.Element {
  const [isSideMenuOpen, switchSideMenuState] = useSideMenu();
  const { isLoading, data = [] } = useTherapeuticTypesData();

  useSetPageEventOnIdChange(PageType.TherapeuticArea);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="pageContainer">
      <FlatSideMenu list={data} isOpen={isSideMenuOpen} onMenuIconClick={switchSideMenuState} />
      <Content isMenuOpen={isSideMenuOpen} />
    </div>
  );
}

export default TherapeuticAreaPage;
