import useBrandsData from '../../hooks/useBrandsData';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import { BRAND_COLUMN_COUNT } from '../components.constant';
import BrandGroupedList from '../groupedList/brandGroupedList/BrandGroupedList';
import Loader from '../loader/Loader';
import SymbolTabs from '../symbolTabs/SymbolTabs';

function Brands(): JSX.Element {
  const { isLoading, data = [] } = useBrandsData();

  useScrollToTop();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <SymbolTabs items={data} />
      <BrandGroupedList items={data} columnCount={BRAND_COLUMN_COUNT} />
    </>
  );
}

export default Brands;
