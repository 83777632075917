import styles from './NoResultsFound.module.scss';

interface NoResultsFoundPropsType {
  searchString: string;
}

function NoResultsFound({ searchString }: NoResultsFoundPropsType): JSX.Element {
  return (
    <div className={styles.container}>
      <p>
        <b>No results found for {searchString}</b>
      </p>
      <p>Check the spelling or try a different search term</p>
    </div>
  );
}

export default NoResultsFound;
