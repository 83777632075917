import { Ad } from 'react-ad-manager';

import { ManufacturerResponse } from '../../../services/api/api.types';
import List from '../../list/List';
import ManufacturerItem from '../../list/manufacturerItem/ManufacturerItem';
import Symbol from '../../symbol/Symbol';
import styles from '../GroupedList.module.scss';

interface ManufacturerGroupedListPropsType {
  items: ManufacturerResponse[];
  columnCount: number;
}

function ManufacturerGroupedList({ items, columnCount }: ManufacturerGroupedListPropsType): JSX.Element {
  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <div id={item.symbol} key={item.symbol} className={styles.list} data-testid="grouped-list">
          <Symbol symbol={item.symbol} disabled={item.disabled} />
          <div className={styles.adContainer}>
            <Ad
              adUnit="/22858144285/GTOTC-Homepage/GTOTC-HP-Manufacturers"
              name={`banner-${index + 1}`}
              size={[728, 90]}
              target={[['CTOTC-AlphaNum', [item.symbol]]]}
            />
            <List items={item.manufacturers} columnCount={columnCount} ItemComponent={ManufacturerItem} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ManufacturerGroupedList;
