import React from 'react';

import SearchArea from './searchArea/SearchArea';
import SearchHeading from './searchHeading/SearchHeading';

function SearchPanel(): JSX.Element {
  return (
    <>
      <SearchHeading />
      <SearchArea />
    </>
  );
}

export default SearchPanel;
