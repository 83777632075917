import { RefObject, useCallback, useEffect } from 'react';

interface ScrollToActiveLinkReturnType {
  (): Element | null | undefined;
}

const useScrollToActiveLink = (ref: RefObject<HTMLDivElement>): ScrollToActiveLinkReturnType => {
  const getActiveLink = useCallback(() => ref.current?.querySelector(`a[class*=link_active]`), [ref]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getActiveLink()?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 2000);

    return () => clearTimeout(timer);
  }, [getActiveLink]);

  return getActiveLink;
};

export default useScrollToActiveLink;
