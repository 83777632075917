import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import styles from './CardList.module.scss';
import { Link } from '../../../services/api/api.types';

interface CardLinksPropsType {
  links: Link[];
}

function CardLinks({ links }: CardLinksPropsType): JSX.Element {
  return (
    <div>
      <b>Links</b>
      <ul className={styles.list}>
        {links.map(({ name, url }) => (
          <li key={name}>
            <OpenInNewIcon />
            <a href={url} target="_blank" rel="noreferrer">
              {name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CardLinks;
