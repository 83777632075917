import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import sortBy from 'lodash/sortBy';

import styles from './ProductTable.module.scss';
import { Product } from '../../../services/api/api.types';
import { useTableSettings } from '../../../utils/ProductTableSettingsProvider';

interface ProductTablePropsType {
  products: Product[];
}

function ProductTable({ products }: ProductTablePropsType): JSX.Element {
  const tableHeaders = useTableSettings();
  const sortedHeaders = sortBy(tableHeaders, (item) => item.sortOrder);

  const updateProductProperties = (product: Product): Product => {
    let result = {} as Product;

    sortedHeaders.forEach(({ productProperty }) => {
      const objValue = product[productProperty as keyof Product];

      result = {
        ...result,
        [productProperty]: productProperty === 'retailPrice' && objValue ? `£${objValue}` : objValue,
      };
    });

    return result;
  };

  const sortedProducts = products.map((product) => updateProductProperties(product));

  return (
    <Table data-testid="product-table">
      <TableHead className={styles.header}>
        <TableRow>
          {sortedHeaders.map(({ headerLabel, productProperty }) => (
            <TableCell key={productProperty}>{headerLabel}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className={styles.body}>
        {sortedProducts.map((item) => (
          <TableRow key={item.pipCode}>
            {Object.entries(item).map(([key, value]) => (
              <TableCell key={key}>{value}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default ProductTable;
