import { NavLink } from 'react-router-dom';

import styles from './BrandItem.module.scss';
import { ROUTER_URL } from '../../../router.constant';
import { BaseEntity } from '../../../services/api/api.types';

interface BrandItemPropsType {
  item: BaseEntity<number>;
}

function BrandItem({ item: { code, name } }: BrandItemPropsType): JSX.Element {
  return (
    <NavLink to={`/${ROUTER_URL.PRODUCTS}/${code}`} className={styles.item}>
      {name}
    </NavLink>
  );
}

export default BrandItem;
