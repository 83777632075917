import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

const useSideMenu = (): [boolean, () => void] => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(true);

  const handleResize = debounce(() => window.dispatchEvent(new CustomEvent('resize')), 100);

  // Used to update library component - Tab Indicator (SymbolTabs component)
  useEffect(() => {
    handleResize();
  }, [handleResize, isSideMenuOpen]);

  const switchSideMenuState = (): void => {
    setIsSideMenuOpen((prevState) => !prevState);
  };

  return [isSideMenuOpen, switchSideMenuState];
};

export default useSideMenu;
