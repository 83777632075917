import { createBrowserRouter, Navigate } from 'react-router-dom';

import Area from './components/area/Area';
import Brand from './components/brand/Brand';
import { NOT_FOUND_STATUS_CODE } from './components/components.constant';
import BrandsPage from './pages/BrandsPage';
import { ErrorPageByUrl, Fallback } from './pages/ErrorPage';
import HomePage from './pages/HomePage';
import LayoutPage from './pages/LayoutPage';
import SearchResultsPage from './pages/SearchResultsPage';
import TherapeuticAreaPage from './pages/TherapeuticAreaPage';
import { ROUTER_URL } from './router.constant';
import ProductTableSettingsProvider from './utils/ProductTableSettingsProvider';

export const router = createBrowserRouter([
  {
    path: ROUTER_URL.HOME,
    element: <LayoutPage />,
    errorElement: <Fallback isRouterError error={{}} resetErrorBoundary={() => {}} />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: ROUTER_URL.THERAPEUTIC_TYPES,
        element: <TherapeuticAreaPage />,
        children: [
          {
            path: ':group/:id',
            element: <Area />,
          },
        ],
      },
      {
        path: ROUTER_URL.PRODUCTS,
        element: <BrandsPage />,
        children: [
          {
            path: ':id',
            element: (
              <ProductTableSettingsProvider>
                <Brand />
              </ProductTableSettingsProvider>
            ),
          },
        ],
      },
      {
        path: ROUTER_URL.SEARCH_RESULTS,
        element: <SearchResultsPage />,
      },
      {
        path: `${ROUTER_URL.ERROR}/*`,
        element: <ErrorPageByUrl />,
      },
      {
        path: '*',
        element: (
          <Navigate replace to={{ pathname: `/${ROUTER_URL.ERROR}`, search: `?statusCode=${NOT_FOUND_STATUS_CODE}` }} />
        ),
      },
    ],
  },
]);
