import { AxiosError } from 'axios';
import { ErrorBoundary } from 'react-error-boundary';
import { useQuery } from 'react-query';
import { Outlet, useLocation } from 'react-router-dom';

import { Fallback } from './ErrorPage';
import Loader from '../components/loader/Loader';
import { isDevelopmentMode } from '../config';
import { ROUTER_URL } from '../router.constant';
import { getWebToken, login } from '../services/api/api.service';
import {
  redirectToErrorPage,
  removeNotAllowedFlag,
  removeWebToken,
  setApiToken,
  validateToken,
} from '../services/auth.service';
import { PageType, setPageViewEvent } from '../utils/analyticsDataLayer';

function LayoutPage(): JSX.Element {
  const location = useLocation();
  const isErrorPage = location.pathname.includes(ROUTER_URL.ERROR);

  const { data, isLoading: isAuthTokenLoading } = useQuery('auth-token-details', () => getWebToken(), {
    onSuccess: ({ jwtToken, updatedDetails: { userRole } }) => {
      validateToken(jwtToken);
      setPageViewEvent(PageType.Home, userRole);
    },
    onError: (error) => onAuthTokenDetailsError((error as AxiosError).response?.status),
    enabled: !isErrorPage && !isDevelopmentMode,
  });

  const { isLoading: isLoginLoading } = useQuery('login', () => login(), {
    onSuccess: ({ token }) => setApiToken(token),
    onError: (error) => redirectToErrorPage((error as AxiosError).response?.status),
    enabled: !isErrorPage && (isDevelopmentMode ? true : !!data?.jwtToken),
  });

  const onAuthTokenDetailsError = (statusCode: number | undefined): void => {
    removeNotAllowedFlag();
    removeWebToken();
    redirectToErrorPage(statusCode);
  };

  if (isAuthTokenLoading || isLoginLoading) {
    return <Loader fullPage />;
  }

  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      <Outlet />
    </ErrorBoundary>
  );
}

export default LayoutPage;
