import { MutableRefObject, RefObject, useCallback, useRef } from 'react';

export interface RefStoreReturnType {
  store: MutableRefObject<RefObject<HTMLDivElement>[]>;
  addToStore: (ref: HTMLDivElement | null) => void;
}

const useRefStore = (): RefStoreReturnType => {
  const store = useRef([] as RefObject<HTMLDivElement>[]);

  const addToStore = useCallback((ref: HTMLDivElement | null): void => {
    if (ref && !store.current.find((item) => item.current?.textContent === ref.textContent)) {
      store.current.push({ current: ref } as RefObject<HTMLDivElement>);
    }
  }, []);

  return { store, addToStore };
};

export default useRefStore;
