import { QueryClientProvider, QueryClient } from 'react-query';
import { RouterProvider } from 'react-router-dom';

import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import { router } from './router';
import FooterRefProvider from './utils/FooterRefProvider';
import OffsetProvider from './utils/OffsetProvider';
import { shouldErrorBeDisplayed } from './utils/shouldErrorBeDisplayed';

function App(): JSX.Element {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        useErrorBoundary: (error) => shouldErrorBeDisplayed(error),
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <OffsetProvider>
        <FooterRefProvider>
          <Header />
          <RouterProvider router={router} />
          <Footer />
        </FooterRefProvider>
      </OffsetProvider>
    </QueryClientProvider>
  );
}

export default App;
