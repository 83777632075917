import { useScrollToTop } from '../../hooks/useScrollToTop';
import useTherapeuticTypesData from '../../hooks/useTherapeuticTypesData';
import AreaGroup from '../areaGroup/AreaGroup';
import Loader from '../loader/Loader';

function Areas(): JSX.Element {
  const { isLoading, data = [] } = useTherapeuticTypesData();

  useScrollToTop();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {data.map((type) => (
        <AreaGroup group={type} key={type.name} />
      ))}
    </>
  );
}

export default Areas;
