import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Tab } from '@mui/material';
import sumBy from 'lodash/sumBy';
import { SyntheticEvent, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import AreasSearchResult from './AreasSearchResult';
import ManufacturersSearchResult from './ManufacturersSearchResult';
import NoResultsFound from './NoResultsFound';
import ProductsSearchResult from './ProductsSearchResult';
import { STALE_TIME } from '../../hooks/hooks.constant';
import { useHashNavigation } from '../../hooks/useHashNavigation';
import useSetTabEventToAnalytics from '../../hooks/useSetTabEventToAnalytics';
import { getSearchResults } from '../../services/api/api.service';
import { SearchResult } from '../../services/api/api.types';
import { setSearchEvent } from '../../utils/analyticsDataLayer';
import { SearchReturnToTabValue, INCONGRUOUS_TAB_CLASSES, TabNames, TabValues } from '../components.constant';
import Loader from '../loader/Loader';
import styles from '../tabs/Tabs.module.scss';

function SearchTabs(): JSX.Element {
  const [value, handleChange] = useHashNavigation(TabValues.Products);
  const tabsRef = useRef<(HTMLDivElement | null)[]>([]);

  const [search] = useSearchParams();
  const searchString = search.get('searchValue') ?? '';

  const { isLoading, data = {} as SearchResult } = useQuery(
    ['search', searchString],
    () => getSearchResults(searchString),
    {
      enabled: !!searchString,
      staleTime: STALE_TIME,
      onSuccess: (result) => {
        setSearchEvent(
          searchString,
          sumBy(
            result.brands.filter((item) => !item.disabled),
            (item) => item.brands.length
          ),
          sumBy(
            result.manufacturers.filter((item) => !item.disabled),
            (item) => item.manufacturers.length
          ),
          sumBy(result.therapeuticTypes, (type) => type.therapeuticAreas.length)
        );
      },
    }
  );
  const { brands = [], manufacturers = [], therapeuticTypes = [], isEmpty } = data;

  useSetTabEventToAnalytics();

  const getTabName = (name: TabNames, length: number): string => `${name} (${length})`;

  const addToTabsRef = (ref: HTMLDivElement | null): void => {
    tabsRef.current?.push(ref);
  };

  const selectTabWithValue = (searchResult: SearchResult): void => {
    const firstNotEmptyProperty =
      Object.entries(searchResult).find(
        ([_key, arrayValue]: [string, unknown[] | boolean]) => Array.isArray(arrayValue) && !!arrayValue.length
      )?.[0] ?? 'brands';

    handleChange(
      {} as SyntheticEvent,
      TabValues[
        SearchReturnToTabValue[firstNotEmptyProperty as keyof typeof SearchReturnToTabValue] as keyof typeof TabValues
      ]
    );
  };

  const productsTabName = getTabName(
    TabNames.Products,
    sumBy(
      brands.filter((item) => !item.disabled),
      (item) => item.brands.length
    )
  );
  const manufacturerTabName = getTabName(
    TabNames.Manufacturers,
    sumBy(
      manufacturers.filter((item) => !item.disabled),
      (item) => item.manufacturers.length
    )
  );
  const therapeuticAreasTabName = getTabName(
    TabNames.TherapeuticAreas,
    sumBy(therapeuticTypes, (type) => type.therapeuticAreas.length)
  );

  useEffect(() => {
    tabsRef.current?.forEach((el) => {
      if (INCONGRUOUS_TAB_CLASSES.every((className) => el?.classList.contains(className))) {
        selectTabWithValue(data);
      }
    });
  });

  if (isLoading) {
    return <Loader />;
  }

  if (isEmpty) {
    return <NoResultsFound searchString={searchString} />;
  }

  return (
    <TabContext value={value}>
      <TabList className={styles.tabList} onChange={handleChange}>
        <Tab
          label={productsTabName}
          value={TabValues.Products}
          disabled={!brands.length}
          data-text={productsTabName}
          ref={(ref) => addToTabsRef(ref)}
        />
        <Tab
          label={manufacturerTabName}
          value={TabValues.Manufacturers}
          disabled={!manufacturers.length}
          data-text={manufacturerTabName}
          ref={(ref) => addToTabsRef(ref)}
        />
        <Tab
          label={therapeuticAreasTabName}
          value={TabValues.Areas}
          disabled={!therapeuticTypes.length}
          data-text={therapeuticAreasTabName}
          ref={(ref) => addToTabsRef(ref)}
        />
      </TabList>
      <TabPanel value={TabValues.Products} className={styles.tabPanel}>
        <ProductsSearchResult searchResult={brands} />
      </TabPanel>
      <TabPanel value={TabValues.Manufacturers} className={styles.tabPanel}>
        <ManufacturersSearchResult searchResult={manufacturers} />
      </TabPanel>
      <TabPanel value={TabValues.Areas} className={`${styles.tabPanel} ${styles.searchAreas}`}>
        <AreasSearchResult searchResult={therapeuticTypes} />
      </TabPanel>
    </TabContext>
  );
}

export default SearchTabs;
