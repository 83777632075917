import { createContext, ReactNode, RefObject, useContext, useMemo, useState } from 'react';

interface FooterRefProviderPropsType {
  children?: ReactNode;
}

interface ContextType {
  footerRef: RefObject<HTMLDivElement> | null;
  setRef: (ref: RefObject<HTMLDivElement> | null) => void;
}

const FooterRefContext = createContext({} as ContextType);

function FooterRefProvider({ children = null }: FooterRefProviderPropsType): JSX.Element {
  const [footerRef, setFooterRef] = useState<RefObject<HTMLDivElement> | null>(null);

  const setRef = (ref: RefObject<HTMLDivElement> | null): void => {
    setFooterRef(ref);
  };

  const contextValue = useMemo(() => ({ footerRef, setRef }), [footerRef]);

  return <FooterRefContext.Provider value={contextValue}>{children}</FooterRefContext.Provider>;
}

export const useFooterRef = (): RefObject<HTMLDivElement> | null => {
  const { footerRef } = useContext(FooterRefContext);

  return footerRef;
};

export const useSetFooterRef = (): ((ref: RefObject<HTMLDivElement> | null) => void) => {
  const { setRef } = useContext(FooterRefContext);

  return setRef;
};

export default FooterRefProvider;
