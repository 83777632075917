import { Container, IconButton, SvgIcon } from '@mui/material';
import { ElementType, useEffect, useRef } from 'react';

import styles from './Footer.module.scss';
import { footerLinks } from '../../config';
import { ReactComponent as FacebookIcon } from '../../icons/facebook-f.svg';
import { ReactComponent as LinkedInIcon } from '../../icons/linkedin-in.svg';
import { ReactComponent as TwitterIcon } from '../../icons/twitter.svg';
import { useSetFooterRef } from '../../utils/FooterRefProvider';
import { SocialMedia } from '../components.constant';

function SocialMediaButton({ type }: { type: SocialMedia }): JSX.Element {
  const getIcon = (): ElementType => {
    switch (type) {
      case SocialMedia.Facebook:
        return FacebookIcon;
      case SocialMedia.Twitter:
        return TwitterIcon;
      case SocialMedia.LinkedIn:
        return LinkedInIcon;
      default:
        return FacebookIcon;
    }
  };

  return (
    <IconButton className={styles[type]} href={footerLinks[type] ?? ''} target="_blank">
      <SvgIcon inheritViewBox component={getIcon()} />
    </IconButton>
  );
}

function Footer(): JSX.Element {
  const { contactLink, groupLinks } = footerLinks;

  const ref = useRef<HTMLDivElement>(null);
  const setFooterRef = useSetFooterRef();

  useEffect(() => {
    setFooterRef(ref);
  });

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.main}>
        <Container maxWidth="lg">
          <div className={styles.row}>
            <div className={styles.description}>
              <img alt="logo" src="/logo.svg" />
              <span>
                C+D Data own and maintain a database of over 100,000 pharmacy products which can be exported in a number
                of formats to suit your needs.
              </span>
            </div>
            <div className={styles.linkBar}>
              {groupLinks.map(({ name: groupName, links }) => (
                <div key={groupName} className={styles.column}>
                  <span className={styles.title}>{groupName}</span>
                  {links.map(({ name, url }) => (
                    <a key={name} href={url} target="_blank" rel="noreferrer">
                      {name}
                    </a>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <span className={styles.title}>Social Media</span>
              <div className={styles.icons}>
                <SocialMediaButton type={SocialMedia.Facebook} />
                <SocialMediaButton type={SocialMedia.Twitter} />
                <SocialMediaButton type={SocialMedia.LinkedIn} />
              </div>
            </div>
            <div className={styles.column}>
              <span className={styles.title}>For General Information Contact Us On</span>
              <a href={`mailto:${contactLink}`}>{contactLink}</a>
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.bottom}>
        <Container maxWidth="lg">
          {new Date().getFullYear()} | C+D is owned and operated by C&D Intelligence U.K. Limited
        </Container>
      </div>
    </div>
  );
}

export default Footer;
