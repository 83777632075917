import { useQuery, UseQueryResult } from 'react-query';

import { STALE_TIME } from './hooks.constant';
import { getBrands } from '../services/api/api.service';
import { BrandResponse } from '../services/api/api.types';

const useBrandsData = (): UseQueryResult<BrandResponse[]> =>
  useQuery('brands', () => getBrands(), {
    staleTime: STALE_TIME,
  });

export default useBrandsData;
