import jwtDecode from 'jwt-decode';

import { FORBIDDEN_STATUS_CODE } from '../components/components.constant';
import configuration from '../config';
import { isExpectedStatusCode } from '../utils/shouldErrorBeDisplayed';

export interface JWTPayload extends Partial<Record<string, unknown>> {
  username: string;
  refreshToken: string;
  otcAccessAllowed: boolean;
  userRole: string;
  exp: number;
  iat: number;
}

export const getApiToken = (): string | null => localStorage.getItem('token');
export const setApiToken = (token: string): void => localStorage.setItem('token', token);

export const getWebToken = (): string | null => localStorage.getItem('webToken');
export const setWebToken = (token: string): void => localStorage.setItem('webToken', token);
export const removeWebToken = (): void => localStorage.removeItem('webToken');

export const getNotAllowedFlag = (): string | null => localStorage.getItem('notAllowed');
export const setNotAllowedFlag = (): void => localStorage.setItem('notAllowed', 'true');
export const removeNotAllowedFlag = (): void => localStorage.removeItem('notAllowed');

export const getRefreshToken = (): string | null => {
  const webToken = getWebToken();

  if (webToken) {
    const { refreshToken } = jwtDecode(webToken) as JWTPayload;

    return refreshToken;
  }

  return null;
};

export const redirectToErrorPage = (statusCode: number | undefined): void => {
  if (statusCode) {
    if (isExpectedStatusCode(statusCode)) {
      window.location.href = `/error?statusCode=${statusCode}`;
    }
  } else {
    redirectToLoginPage();
  }
};

export const redirectToLoginPage = (): void => {
  window.location.replace(configuration.REACT_APP_AUTH_URL);
};

export const validateToken = (token: string): void => {
  const { otcAccessAllowed = false } = jwtDecode(token) as JWTPayload;

  if (otcAccessAllowed) {
    removeNotAllowedFlag();
    setWebToken(token);
  } else {
    setNotAllowedFlag();
    redirectToErrorPage(FORBIDDEN_STATUS_CODE);
  }
};

export const getUserRole = (): string => {
  const token = getWebToken();

  return token ? (jwtDecode(token) as JWTPayload).userRole : 'unknown';
};
