import { useEffect, useRef } from 'react';

const useTriggerOnceOnMount = (functionToTrigger: () => void): void => {
  const shouldTrigger = useRef(true);

  useEffect(() => {
    if (shouldTrigger.current) {
      shouldTrigger.current = false;
      functionToTrigger();
    }
  }, [functionToTrigger]);
};

export default useTriggerOnceOnMount;
