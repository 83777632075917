import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Tab } from '@mui/material';

import styles from './Tabs.module.scss';
import { useHashNavigation } from '../../hooks/useHashNavigation';
import useSetTabEventToAnalytics from '../../hooks/useSetTabEventToAnalytics';
import Areas from '../areas/Areas';
import Brands from '../brands/Brands';
import { TabNames, TabValues } from '../components.constant';
import Manufacturers from '../manufacturers/Manufacturers';

function Tabs(): JSX.Element {
  const [value, handleChange] = useHashNavigation(TabValues.Products);

  useSetTabEventToAnalytics();

  return (
    <TabContext value={value}>
      <TabList className={styles.tabList} onChange={handleChange}>
        <Tab label={TabNames.Products} value={TabValues.Products} data-text={TabNames.Products} />
        <Tab label={TabNames.Manufacturers} value={TabValues.Manufacturers} data-text={TabNames.Manufacturers} />
        <Tab label={TabNames.TherapeuticAreas} value={TabValues.Areas} data-text={TabNames.TherapeuticAreas} />
      </TabList>
      <TabPanel value={TabValues.Products} className={styles.tabPanel}>
        <Brands />
      </TabPanel>
      <TabPanel value={TabValues.Manufacturers} className={styles.tabPanel}>
        <Manufacturers />
      </TabPanel>
      <TabPanel value={TabValues.Areas} className={`${styles.tabPanel} ${styles.areas}`}>
        <Areas />
      </TabPanel>
    </TabContext>
  );
}

export default Tabs;
