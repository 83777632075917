import styles from './SearchArea.module.scss';
import SearchInput from '../searchInput/SearchInput';

function SearchArea(): JSX.Element {
  return (
    <div className={styles.area} data-testid="search-area">
      <SearchInput />
    </div>
  );
}

export default SearchArea;
