import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import styles from './MenuLink.module.scss';
import { BaseEntity } from '../../../services/api/api.types';
import getTherapeuticTypePath from '../../../utils/getTherapeuticTypePath';

interface MenuLinkPropsType {
  item: BaseEntity<string | number>;
  type?: string;
}

function MenuLink({ item, type = undefined }: MenuLinkPropsType): JSX.Element {
  const path = getTherapeuticTypePath(type, item.code.toString());

  const getLinkClass = (isActive: boolean): string =>
    classNames(styles.link, { [styles.link_active]: isActive, [styles.link_collapsible]: !type });

  return (
    <NavLink replace to={path} className={({ isActive }) => getLinkClass(isActive)}>
      {item.name}
    </NavLink>
  );
}

export default MenuLink;
