import { useScrollToTop } from '../../hooks/useScrollToTop';
import { TherapeuticType } from '../../services/api/api.types';
import AreaGroup from '../areaGroup/AreaGroup';

interface AreasSearchResultPropsType {
  searchResult: TherapeuticType[];
}

function AreasSearchResult({ searchResult }: AreasSearchResultPropsType): JSX.Element {
  useScrollToTop();

  return (
    <>
      {searchResult.map((type) => (
        <AreaGroup group={type} key={type.name} />
      ))}
    </>
  );
}

export default AreasSearchResult;
