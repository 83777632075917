import axios from 'axios';

import { ErrorCode, SERVER_ERROR_STATUS_CODE } from '../components/components.constant';

export const shouldErrorBeDisplayed = (error: unknown): boolean => {
  if (axios.isAxiosError(error)) {
    const { response } = error;

    if (response) {
      const { status } = response;

      if (!isExpectedStatusCode(status)) {
        return true;
      }
    }
  }

  return false;
};

export const isExpectedStatusCode = (statusCode: number): boolean =>
  Object.keys(ErrorCode).includes(statusCode.toString()) && statusCode !== SERVER_ERROR_STATUS_CODE;
