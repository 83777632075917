import { BrandResponse } from '../../../services/api/api.types';
import CollapsibleMenuGroup from '../collapsibleMenuGroup/CollapsibleMenuGroup';
import SideMenu, { SideMenuPropsType } from '../SideMenu';

interface CollapsibleSideMenuPropsType extends Omit<SideMenuPropsType, 'children'> {
  list: BrandResponse[];
}

function CollapsibleSideMenu({ list, isOpen, onMenuIconClick }: CollapsibleSideMenuPropsType): JSX.Element {
  return (
    <SideMenu isOpen={isOpen} data-testid="collapsible-side-menu" onMenuIconClick={onMenuIconClick}>
      {list.map((item, index, array) => (
        <CollapsibleMenuGroup key={item.symbol} group={item} isLastGroup={array.length - 1 === index} />
      ))}
    </SideMenu>
  );
}

export default CollapsibleSideMenu;
