import { sanitize } from 'dompurify';
import { ForwardedRef, forwardRef } from 'react';

import styles from './Description.module.scss';

interface DescriptionPropsType {
  answer: string;
}

function DescriptionWithRef({ answer }: DescriptionPropsType, ref: ForwardedRef<null>): JSX.Element {
  return (
    <div className={styles.description} ref={ref}>
      <div dangerouslySetInnerHTML={{ __html: sanitize(answer) }} />
    </div>
  );
}

const Description = forwardRef(DescriptionWithRef);

export default Description;
