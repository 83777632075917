export const BRAND_COLUMN_COUNT = 3;
export const SEARCH_RESULT_COLUMN_COUNT = 1;
export const MANUFACTURER_COLUMN_COUNT = 2;
export const SCROLL_SPY_OFFSET = -90;
export const FIRST_SYMBOL = 'A';
export const MIN_SEARCH_LENGTH = 3;

export const UNAUTHORIZED_STATUS_CODE = 401;
export const FORBIDDEN_STATUS_CODE = 403;
export const NOT_FOUND_STATUS_CODE = 404;
export const SERVER_ERROR_STATUS_CODE = 500;

export const DEFAULT_MESSAGE = 'Something went wrong. Please try again later.';
export const UNHANDLED_ERROR = 'Unexpected Application Error';

export const INCONGRUOUS_TAB_CLASSES = ['Mui-selected', 'Mui-disabled'];

export enum TabNames {
  Products = 'Products',
  Manufacturers = 'Manufacturers',
  TherapeuticAreas = 'Therapeutic Areas',
}

export enum TabValues {
  Areas = 'areas',
  Products = 'products',
  Manufacturers = 'manufacturers',
}

export enum SearchReturnToTabValue {
  therapeuticTypes = 'Areas',
  manufacturers = 'Manufacturers',
  brands = 'Products',
}

export enum AreaTabNames {
  Overview = 'Overview',
  Products = 'Products',
}

export enum AreaTabValues {
  Overview = 'overview',
  Products = 'products',
}

export enum Content {
  Overview = 'Overview of common conditions',
  Treatments = 'Common treatments',
  Recommend = 'Deciding what to recommend',
  Refer = 'When to refer',
  DecisionTree = 'Decision tree',
  Advice = 'Associated advice',
  Remember = 'Remember',
}

export enum SocialMedia {
  Facebook = 'facebook',
  Twitter = 'twitter',
  LinkedIn = 'linkedIn',
}

export const ErrorCode: Record<number, string> = {
  401: 'Not Signed In',
  403: 'Not Subscribed',
  404: 'Page Not Found',
  500: 'Server Error',
};
