import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import ProductList from './productList/ProductList';
import styles from './Products.module.scss';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import {
  getDataForAreaProductsSymbolTabs,
  getTherapeuticAreaProducts,
  getTherapeuticAreaPromotedProduct,
} from '../../services/api/api.service';
import { useSetTableSettings } from '../../utils/ProductTableSettingsProvider';
import BackdropLoader from '../backdrop/BackdropLoader';
import { FIRST_SYMBOL } from '../components.constant';
import PromotedProduct from '../promotedProduct/PromotedProduct';
import FilteringSymbolTabs from '../symbolTabs/filteringSymbolTabs/FilteringSymbolTabs';

function Products(): JSX.Element {
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState('');
  const setTableSettings = useSetTableSettings();

  useScrollToTop();

  const { isLoading: isAreaSymbolTabsLoading, data: symbolTabs = [] } = useQuery(
    ['areaSymbolTabs', id],
    () => getDataForAreaProductsSymbolTabs(id),
    {
      cacheTime: 0,
      onSuccess: (data) => setSelectedTab(data.find((item) => !item.disabled)?.symbol ?? FIRST_SYMBOL),
    }
  );

  const {
    isLoading: isPromotedProductLoading,
    data: promotedProduct = null,
    isFetched: isPromotedProductFetched,
  } = useQuery(['promotedProduct', id], () => getTherapeuticAreaPromotedProduct(id), {
    enabled: symbolTabs.length > 0,
    cacheTime: 0,
  });

  const {
    isLoading: isProductsLoading,
    isFetching: isProductsFetching,
    data: { monographProducts = [] } = {},
  } = useQuery(['areaProducts', id, selectedTab], () => getTherapeuticAreaProducts(selectedTab, id), {
    enabled: !!selectedTab && isPromotedProductFetched,
    onSuccess: ({ productHeaders }) => setTableSettings(productHeaders),
  });

  const onSelectedTabChanges = (newValue: string): void => {
    if (!isProductsFetching) {
      setSelectedTab(newValue);
    }
  };

  if (isAreaSymbolTabsLoading || isPromotedProductLoading || isProductsLoading || isProductsFetching) {
    return <BackdropLoader />;
  }

  return (
    <div className={styles.products} data-testid="products">
      <FilteringSymbolTabs items={symbolTabs} value={selectedTab} onChange={onSelectedTabChanges} />
      {promotedProduct && <PromotedProduct product={promotedProduct} />}
      <ProductList isNavigational products={monographProducts} />
    </div>
  );
}

export default Products;
