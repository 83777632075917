const {
  NODE_ENV,
  REACT_APP_STAGE,
  REACT_APP_API_URI,
  REACT_APP_AUTH_URL,
  REACT_APP_NEWS_LINK,
  REACT_APP_JOBS_LINK,
  REACT_APP_LEARNING_LINK,
  REACT_APP_ADVERTISING_LINK,
  REACT_APP_CONTACT_SUPPORT_LINK,
  REACT_APP_ABOUT_LINK,
  REACT_APP_TERMS_AND_CONDITIONS_LINK,
  REACT_APP_ADVERTISE_WITH_US_LINK,
  REACT_APP_PRIVACY_POLICY_LINK,
  REACT_APP_COOKIE_POLICY_LINK,
  REACT_APP_CONTACT_US_LINK,
  REACT_APP_FACEBOOK,
  REACT_APP_TWITTER,
  REACT_APP_LINKEDIN,
} = process.env;

export const ENDPOINT = {
  AUTH_TOKEN: '/auth-token-details',
  REFRESH_TOKEN: '/auth-refresh-token',
  LOGIN: '/login',
  MANUFACTURER: '/manufacturer',
  BRAND: '/brand',
  THERAPEUTIC_AREA: '/area',
  SEARCH: '/search',
};

export const THERAPEUTIC_AREA_ENTITY = {
  SYMBOLS: 'symbols',
  PRODUCTS: 'products',
  PROMOTED: 'promoted',
};

export const footerLinks = {
  contactLink: REACT_APP_CONTACT_US_LINK,
  facebook: REACT_APP_FACEBOOK,
  twitter: REACT_APP_TWITTER,
  linkedIn: REACT_APP_LINKEDIN,
  groupLinks: [
    {
      name: 'Other C+D Sites',
      links: [
        { name: 'C+D News', url: REACT_APP_NEWS_LINK },
        { name: 'C+D Jobs', url: REACT_APP_JOBS_LINK },
        { name: 'Learning', url: REACT_APP_LEARNING_LINK },
        { name: 'Advertising', url: REACT_APP_ADVERTISING_LINK },
      ],
    },
    {
      name: 'Support',
      links: [{ name: 'Contact us', url: REACT_APP_CONTACT_SUPPORT_LINK }],
    },
    {
      name: 'About',
      links: [
        { name: 'About C+D', url: REACT_APP_ABOUT_LINK },
        { name: 'Terms and conditions', url: REACT_APP_TERMS_AND_CONDITIONS_LINK },
        { name: 'Advertise with us', url: REACT_APP_ADVERTISE_WITH_US_LINK },
      ],
    },
    {
      name: 'Security',
      links: [
        { name: 'Privacy policy', url: REACT_APP_PRIVACY_POLICY_LINK },
        { name: 'Cookie policy', url: REACT_APP_COOKIE_POLICY_LINK },
      ],
    },
  ],
};

export const isDevelopmentMode = NODE_ENV === 'development';

export default {
  STAGE: REACT_APP_STAGE,
  REACT_APP_API_URI: REACT_APP_API_URI || 'http://localhost:5002/dev',
  REACT_APP_AUTH_URL: REACT_APP_AUTH_URL || 'https://www.cddata.co.uk',
};
